<!-- 人才管理 -->
<template>
    <div>
        <!-- 通用标题组件 -->
        <el-card class="box-card">
            <el-breadcrumb separator-class="el-icon-arrow-right div40">
                <el-breadcrumb-item><a @click="request">首页</a></el-breadcrumb-item>
                <el-breadcrumb-item>账号管理</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="fl" style="line-height: 40px;">筛选：</div>
            <div style="text-align: left;">
                <el-select v-model="value" filterable remote reserve-keyword placeholder="请输入账号名" @change="remoteMethod">
                    <el-option
                            v-for="item in states"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                    </el-option>
                </el-select>
                <el-button type="primary" plain @click="getDate" style="margin-left: 10px">刷新</el-button>
            </div>
            <el-table
                    ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%"
                    @selection-change="SelectionChange"
            >
                <el-table-column type="selection" width="55"></el-table-column>
                <el-table-column label="账号" min-width="200px" align="left">
                    <template slot-scope="scope">{{ scope.row.username }}</template>
                </el-table-column>

                <el-table-column label="类型" min-width="200px" align="center">
                    <template slot-scope="scope">
                        <el-tag type="success" v-if="scope.row.owner==='admin'">平台管理员</el-tag>
                        <el-tag type="success" v-else-if="scope.row.owner === 'talent'">个人</el-tag>
                        <el-tag type="success" v-else-if="scope.row.owner === 'enterprise'">单位</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="状态" minhandleEdit-width="200" align="center">
                    <template slot-scope="scope">
                        <el-tag type="success" v-if="scope.row.status===1">正常</el-tag>
                        <el-tag type="warning" v-else-if="scope.row.status===0">锁定</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center" min-width="240" fixed="right">
                    <template slot-scope="scope">
                         <span>
<!--                        <span v-if="scope.row.owner!='admin'">-->
                            <el-button type="danger" size="mini" icon="el-icon-open" v-if="scope.row.status===1"
                                       @click="SetStatus(0,scope.row.id)">锁定</el-button>
                            <el-button type="success" size="mini" icon="el-icon-open" v-else
                                       @click="SetStatus(1,scope.row.id)">解锁</el-button>
                            <el-button size="mini" icon="el-icon-edit" @click="handleEdit(scope.row)">编辑</el-button>
                             <el-button size="mini" icon="el-icon-orange" @click="resetPass(scope.row)">重置密码</el-button>
                        </span>

                    </template>
                </el-table-column>
            </el-table>
            <span id="total">共计{{total}}个账号</span>
            <!--            <el-pagination background layout="prev, pager, next" :total="total" :page-size="pageSize"-->
            <!--                           @current-change="requestPage" />-->
            <el-pagination background layout="prev, pager, next" :total="total" @current-change="requestPage"
                           id="pagination">
            </el-pagination>
        </el-card>

        <el-dialog :title="title" :visible.sync="usernum" width="35%">

            <div class="hr-10"></div>
            <el-transfer v-model="rolevalue" :titles="titles" :props="{ key: 'value', label: 'desc' }" :data="roledata"
                         style="text-align: left">
            </el-transfer>
            <div class="hr-10"></div>
            <el-button @click="usernum = false">取 消</el-button>
            <el-button type="primary" @click="submitForm()">提交</el-button>
        </el-dialog>
    </div>
</template>

<script>
    //import headtitle from "@/components/ControlPanel/headtitle";
    //import selectionsearch from "@/components/ControlPanel/selectionsearch";

    export default {
        components: {
            //headtitle
            //selectionsearch
        },
        name: "settings",

        data() {

            return {
                page: 1,
                pages: 0,
                pageSize: 10,
                total: 0,
                createLoading: false,
                usernum: false,
                titles: ['可选角色', '已选角色'],
                title: '',
                roledata: [],
                rolevalue: [],
                form: {
                    id: '',
                    roleIds: [],
                    status: 0,
                    // name: "",
                    // pass: "",
                },
                tableData: [],
                multipleSelection: [],
                //远程搜索
                loading: false,
                states: [],
                value:''
            };
        },
        created() {
            this.getDate(); //初始化
            this.setstates()
        },
        methods: {
            request() {
                let newPage = this.$router.resolve({
                    name: 'EntryStoma',
                })
                window.open(newPage.href, '_blank')
            },
            setstates(){
                var self = this;
                this.$axios.get("/account/page", {
                    params: {
                        current: 1,
                        size: 3000
                    }
                }).then(response => {
                    response.data.records.forEach(function (item) {
                        self.states.push({value:item.id, label:item.username})

                    })
                }).catch(error => {
                    console.log(error)
                })
            },
            remoteMethod() {
                var self = this;
                if (self.value !== '') {
                    self.$axios.get('/account', {
                        params: {
                            id:self.value
                        }
                    }).then(response => {
                       // console.log(response.data.status)
                       //  console.log(self.tableData)
                        if(response.data.status==200){
                            self.tableData=[]
                            self.tableData.push(response.data.data)
                            self.total=1
                        }

                    }).catch(error => {
                        console.error(error)
                    })
                } else {

                }
            },


            getDate() {
                var self = this;
                //账号查询
                this.$axios.get("/account/page", {
                    params: {
                        current: 1,
                        size: 10
                    }
                }).then(function (res) {
                    console.log(res.data.records)
                    self.value = ''
                    self.tableData = res.data.records;
                    self.total = res.data.total;
                }).catch(function (error) {
                    console.error(error);
                });

                this.$axios.get('/role/page', {
                    params: {
                        current: 1,
                        size: 100
                    }
                }).then(function (res) {

                    self.roledata = []
                    self.rolevalue = []
                    for (var i = 0; i < res.data.data.records.length; i++) {
                        self.roledata.push({
                            value: res.data.data.records[i].id,
                            desc: res.data.data.records[i].name,
                        });
                    }
                    console.log(res)
                })
            },

            //  分页
            requestPage(value) {
                this.page = value;

                this.$axios.get("/account/page", {
                    params: {
                        current: value,
                        size: 10
                    }
                }).then(response => {
                    this.tableData = response.data.records;
                }).catch(error => {
                    console.log(error)
                })
            },
            deletenumber() {
            },
            SelectionChange(val) {
                this.multipleSelection = val;
            },
            submitForm() {
                var self = this;
                this.form.roleIds = this.rolevalue
                this.$axios.put('/account', this.form).then(function (res) {
                    console.log(res.data)
                    if (res.data.status === 200) {
                        self.$message({
                            showClose: true,
                            message: "修改成功",
                            type: "success"
                        });
                        self.usernum = false
                        self.getDate();
                    }
                })

            },
            SetStatus(num, Id) {
                var self = this;
                let daa = {
                    id: Id,
                    status: num
                }
                this.$axios.put('/account', daa).then(function (res) {
                    if (res.data.status === 200) {
                        self.$message({
                            showClose: true,
                            message: "修改成功",
                            type: "success"
                        });
                        self.requestPage(self.page)

                    }
                })
            },

            handleEdit(row) {
                var self = this;
                this.usernum = true;
                this.form.id = row.id
                this.form.status = row.status
                this.rolevalue = [];
                this.title = row.username + '修改'
                this.$axios.get('/account?id=' + row.id).then(function (res) {
                    console.log(res.data.data.roles)
                    for (var i = 0; i < res.data.data.roles.length; i++) {
                        self.rolevalue.push(res.data.data.roles[i].id);
                    }
                })

            },
            resetPass(row) {
                var self = this;
                this.$confirm('您重置密码吗？ 默认密码：Aa#123')
                    .then(_ => {
                        //console.log(row.username)
                        this.$axios.put('/account/password/reset', {
                            newPassword: 'Aa#123',
                            username: row.username
                        }).then(response => {
                            console.log(response.status)
                            if (response.status === 200) {
                                self.$message({
                                    showClose: true,
                                    message: '重置密码成功',
                                    type: "success"
                                });
                            }
                        });
                    }).catch(_ => {
                    self.$message({
                        showClose: true,
                        message: "已取消",
                        type: "success"
                    });
                });
            }
        }
    };
</script>

<style lang="less" scoped>
    .el-card {
        position: relative;

        #total {
            position: absolute;
            bottom: 40px;
            left: 30px;
        }
    }

    .headtit {
        background: #1090e7;
        height: 50px;
        text-align: left;
        line-height: 50px;
        padding: 0 0 0 16px;
        color: #fff;
        font-size: 16px;
    }

    .checkboxlist {
        padding: 15px 0 0 12px;
        height: 100px;
        border: 1px #ddd solid;

        .checkboxitem {
            width: 100px;
            margin-right: 0;
            height: 33px;
        }
    }

    #pagination {
        margin: 32px 0 16px 0;
    }
</style>